import React, { useEffect, useRef, useCallback, useState } from "react";

const VideoStream = ({
  videoConstraints,
  videoRef,
  canvasRef,
  setImageData,
  setProductDetail,
  imageData,
}) => {
  const streamRef = useRef(null);
  const previousImageDataRef = useRef(null);
  const [deff, setDeff] = useState(false);
  const [threshold, setThreshold] = useState(0.9);
  const [devices, setDevices] = useState([]);
  const [selectedDeviceId, setSelectedDeviceId] = useState("");

  useEffect(() => {
    const getDevices = async () => {
      try {
        const deviceInfos = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = deviceInfos.filter(
          (device) => device.kind === "videoinput"
        );
        setDevices(videoDevices);
        setSelectedDeviceId(videoDevices.length > 0 ? videoDevices[0].deviceId : "");
      } catch (error) {
        console.error("Error fetching devices:", error);
      }
    };

    getDevices();
  }, []);

  const sendFrameToBackend = useCallback(
    async (imageData) => {
      try {
        console.log(threshold);
        const response = await fetch(
          "https://apidev.picklist.ethicstechnology.net/api/send-frame",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              frame: imageData,
              picknote: "WH/OUT/122366",
              token: "sda",
              threshold: threshold,
            }),
          }
        );
        const data = await response.json();
        setImageData(data.image);
        setProductDetail(data);
      } catch (error) {
        console.error("Error sending frame to backend:", error);
      }
    },
    [setImageData, setProductDetail, threshold]
  );

  const detectFrameChange = useCallback(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext("2d");
    canvas.width = videoConstraints.width;
    canvas.height = videoConstraints.height;

    if (videoRef.current) {
      ctx.drawImage(
        videoRef.current,
        0,
        0,
        videoConstraints.width,
        videoConstraints.height
      );

      const imgData = ctx.getImageData(
        0,
        0,
        videoConstraints.width,
        videoConstraints.height
      );

      if (previousImageDataRef.current) {
        const previousImgData = previousImageDataRef.current;
        let meanDifference = 0;

        for (let i = 0; i < imgData.data.length; i += 4) {
          const diff =
            Math.abs(imgData.data[i] - previousImgData[i]) / 255 +
            Math.abs(imgData.data[i + 1] - previousImgData[i + 1]) / 255 +
            Math.abs(imgData.data[i + 2] - previousImgData[i + 2]) / 255;

          meanDifference +=
            diff / (videoConstraints.width * videoConstraints.height);
        }

        if (meanDifference > 0.1) {
          const imageData = canvas.toDataURL("image/jpeg");
          setDeff(true);
          setTimeout(() => {
            sendFrameToBackend(imageData);
          }, 1000);
        } else {
          setDeff(false);
          setImageData(null);
        }
      }

      previousImageDataRef.current = imgData.data;
    }
  }, [sendFrameToBackend, videoConstraints]);

  const startVideo = useCallback(async () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: {
            ...videoConstraints,
            deviceId: selectedDeviceId ? { exact: selectedDeviceId } : undefined,
          },
        });
        videoRef.current.srcObject = stream;
        videoRef.current.onloadedmetadata = () => {
          videoRef.current.play();
        };
        streamRef.current = stream;
      } catch (error) {
        console.error("Error accessing webcam:", error);
      }
    } else {
      console.error(
        "navigator.mediaDevices.getUserMedia is not supported in this browser."
      );
    }
  }, [selectedDeviceId, videoConstraints, videoRef]);

  useEffect(() => {
    startVideo();

    const captureInterval = setInterval(detectFrameChange, 1000);

    return () => {
      clearInterval(captureInterval);
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop());
      }
    };
  }, [startVideo, detectFrameChange]);

  useEffect(() => {
    if (threshold < 0.1) {
      setThreshold(0.1);
    }
    if (threshold > 0.9) {
      setThreshold(0.9);
    }
  }, [threshold]);

  return (
    <div>
      <div className="mt-2">
        {deff ? (
          <div className="alert alert-success" role="alert">
            sending frame to backend
          </div>
        ) : (
          <div className="alert alert-danger" role="alert">
            no frame change detected
          </div>
        )}
      </div>
      <div className="mt-2">
        <div className="row align-items-center">
          <div className="col-auto">
            <span>Change Threshold:</span>
          </div>
          <div className="col-auto">
            <button
              className="btn btn-primary"
              onClick={() => setThreshold(threshold - 0.1)}
            >
              -
            </button>
          </div>
          <div className="col-auto">
            <span className="mx-2">{threshold.toFixed(1)}</span>
          </div>
          <div className="col-auto">
            <button
              className="btn btn-primary"
              onClick={() => setThreshold(threshold + 0.1)}
            >
              +
            </button>
          </div>
        </div>
      </div>

      {imageData && (
        <img
          src={`data:image/jpeg;base64,${imageData}`}
          alt="Product"
          width={750}
          className={`p-2 border ${imageData ? "" : "d-none"}`}
        />
      )}
      <video
        ref={videoRef}
        autoPlay
        playsInline
        width={750}
        height={600}
        className={imageData ? "d-none" : ""}
      />
      <canvas ref={canvasRef} style={{ display: "none" }} />
      <select
        value={selectedDeviceId}
        onChange={(e) => setSelectedDeviceId(e.target.value)}
      >
        {devices.length === 0 && <option value="">None</option>}
        {devices.map((device) => (
          <option key={device.deviceId} value={device.deviceId}>
            {device.label || `Camera ${device.deviceId}`}
          </option>
        ))}
      </select>
    </div>
  );
};

export default VideoStream;

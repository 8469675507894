import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import VideoStream from "./VideoStream";
import LineItemsTable from "./LineItemsTable";

const videoConstraints = {
  width: 1920,
  height: 1080,
  facingMode: "user",
};

const WebCam = () => {
  const [productDetail, setProductDetail] = useState({
    batchNumber: "",
    mfgDate: "",
    expDate: "",
    original: "",
    price: "",
  });
  const [lineItems, setLineItems] = useState([]);
  const [imageData, setImageData] = useState(null);
  const audioRef = useRef(null);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  const handleAddRow = () => {
    setLineItems((prevLineItems) => [
      ...prevLineItems,
      { batchNumber: "", mfgDate: "", expDate: "", original: "", price: "" },
    ]);
  };

  const handleLineItemChange = (index, field, value) => {
    setLineItems((prevLineItems) => {
      const updatedLineItems = [...prevLineItems];
      updatedLineItems[index][field] = value;
      return updatedLineItems;
    });
  };

  const handleDeleteRow = (index) => {
    setLineItems((prevLineItems) =>
      prevLineItems.filter((_, i) => i !== index)
    );
  };

  const handleSave = () => {
    if (lineItems.length === 0) {
      return;
    }
    const dataToSave = { data: lineItems };
    axios
      .post("http://192.168.0.194:3001/api/save-data", dataToSave)
      .then((res) => {
        if (res.status === 200) {
          setLineItems([]);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    const { batchNumber, mfgDate, expDate, original, price } = productDetail;
    if (batchNumber) {
      audioRef.current.play();
      // not add if the same product is already in the list
      if (
        lineItems.some(
          (item) =>
            item.batchNumber === batchNumber
        )
      ) {
        return;
      }
      setLineItems((prevLineItems) => [
        ...prevLineItems,
        { batchNumber, mfgDate, expDate, original, price },
      ]);
      setProductDetail({
        batchNumber: "",
        mfgDate: "",
        expDate: "",
        original: "",
        price: "",
      });
    }
  }, [productDetail]);



  return (
    <div className="text-center">
      <audio ref={audioRef}>
        <source src="/beep.mp3" type="audio/mp3" />
      </audio>
      <div className="row">
        <div className="col">
          <VideoStream
            videoConstraints={videoConstraints}
            videoRef={videoRef}
            canvasRef={canvasRef}
            setImageData={setImageData}
            setProductDetail={setProductDetail}
            imageData={imageData}
          />
        </div>
      </div>
      <div className="row">
        <div className="col align-items-center flex-column d-flex p-0">
          <LineItemsTable
            lineItems={lineItems}
            handleLineItemChange={handleLineItemChange}
            handleDeleteRow={handleDeleteRow}
            handleAddRow={handleAddRow}
            handleSave={handleSave}
          />
        </div>
      </div>
    </div>
  );
};

export default WebCam;

import React from "react";

const Row = ({ index, item, handleLineItemChange, handleDeleteRow }) => {
  const handleChange = (field, value) => {
    handleLineItemChange(index, field, value);
  };

  return (
    <tr>
      <td className="pt-2 pb-2 ps-3 pe-3 border">
        <input
          type="text"
          value={item.original}
          onChange={(e) => handleChange("original", e.target.value)}
        />
      </td>
      <td className="pt-2 pb-2 ps-3 pe-3 border">
        <input
          type="text"
          value={item.batchNumber}
          onChange={(e) => handleChange("batchNumber", e.target.value)}
        />
      </td>
      <td className="pt-2 pb-2 ps-3 pe-3 border">
        <input
          type="text"
          value={item.mfgDate}
          onChange={(e) => handleChange("mfgDate", e.target.value)}
        />
      </td>
      <td className="pt-2 pb-2 ps-3 pe-3 border">
        <input
          type="text"
          value={item.expDate}
          onChange={(e) => handleChange("expDate", e.target.value)}
        />
      </td>
      <td className="pt-2 pb-2 ps-3 pe-3 border">
        <input
          type="text"
          value={item.price}
          onChange={(e) => handleChange("price", e.target.value)}
        />
      </td>
      <td className="pt-2 pb-2 ps-3 pe-3 border">
        <button
          className="btn btn-danger"
          onClick={() => handleDeleteRow(index)}
        >
          Delete
        </button>
      </td>
    </tr>
  );
};

export default Row;
